import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import WorkIcon from '@mui/icons-material/Work';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Paper, InputBase, MenuItem, Select, InputLabel, FormControl, IconButton, FormHelperText, Alert, Snackbar, InputAdornment } from '@mui/material';
import Modal from '@mui/material/Modal';
import { set } from 'animejs';
import { Form } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { PRODUCTION_API_KEY, PRODUCTION_API_DOMAIN} from '../utils/domain';


const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[500],
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px 16px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '14px',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
  },
}));


const FormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '50px',
  gap: '16px',
  maxWidth: '1250px',
  margin: '0 auto',
});


const FormButton = styled(Button)({
  width: '100%',
  backgroundColor: 'blue',
  color: 'white',
  '&:hover': {
    backgroundColor: 'darkblue',
  },
});

const FormField = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
});

const FormIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const FormInput = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
});

const OTPInput = styled(InputBase)(({ theme }) => ({
  textAlign: 'center',
  width: '3rem',
  // height: '3rem',
  margin: '0 0.5rem',
  // fontSize: '1.5rem',
  color: theme.palette.text.primary,
  backgroundColor: 'transparent',
  border: 'none',
  borderBottom: `2px solid ${theme.palette.text.secondary}`,
}));

const OtpBox = styled(Paper)(({ theme }) => ({
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0px',
  backgroundColor: '#f5f5f5',
  boxShadow: theme.shadows[3],
}));

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function FormMuiTab2() {
  const [valueOfOtp, setValueOfOtp] = useState(null);
  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '' });
  const [panNo, setPanno] = useState({ pan1: '', pan2: '', pan3: '', pan4: '', pan5: '', pan6: '', pan7: '', pan8: '', pan9: '', pan10: '' });
  const [adhharNo, setAdharno] = useState({ aadhar1: '', aadhar2: '', aadhar3: '', aadhar4: '', aadhar5: '', aadhar6: '', aadhar7: '', aadhar8: '', aadhar9: '', aadhar10: '', aadhar11: '', aadhar12: '' });
  const [NiyoLeadID, setNiyoLeadID] = useState('')
  const [dataFromAadhaar, setDataFromAadhaar] = useState({})
  const [FormsubmitError, setFormsubmitError] = useState('');
  const [partnerOnboarded, setPartnerOnboarded] = useState('');
  const [loading,setLoading]= useState(false);


  // Combine OTP values
  let combinedOtp = `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}`;

  let combinedAadhar = `${adhharNo.aadhar1}${adhharNo.aadhar2}${adhharNo.aadhar3}${adhharNo.aadhar4}${adhharNo.aadhar5}${adhharNo.aadhar6}${adhharNo.aadhar7}${adhharNo.aadhar8}${adhharNo.aadhar9}${adhharNo.aadhar10}${adhharNo.aadhar11}${adhharNo.aadhar12}`;

  let AadharOtpMix = `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}${otp.otp5}${otp.otp6}`
  // Combine Pan values
  let combinedPan = `${panNo.pan1}${panNo.pan2}${panNo.pan3}${panNo.pan4}${panNo.pan5}${panNo.pan6}${panNo.pan7}${panNo.pan8}${panNo.pan9}${panNo.pan10}`;


  const [formData, setFormData] = useState({
    otp: combinedOtp,
    name_of_entry: '',
    Email: '',
    Aadhaar: combinedAadhar,
    otpAadhar: AadharOtpMix,
    Pan: combinedPan,
    aadharReqId: '',
    Profession: '',
    Mobile_number: '',
    Entity_Name: '',
    city: '',
    state: '',
    Pincode: '',
    accessCode: '',
    createPassword: ''
  });

  const [timerStart, setTimerStart] = useState(false);
  const [dataField, setData] = useState({
    mob: false,
    email: false,
    pan: false,
    aadhar: false,
    entity_name: false,
    pincode: false,
    pinVerify: false,
    allpendData: false
  })
  const [state, setState] = useState({
    opensnakbar: false,
    vertical: 'top',
    horizontal: 'center',
    timer:0,
    enableTimer:false,
    resend:false,
    isOtpGenerated:false
  });
  const [isPartnerOnboardingPending, setIsPartnerOnboardingPending] = useState(false);
  const [mobileError, setMobileError] = useState('');
  const [createpassError, setCreatepassError] = useState('');
  const [accessError, setAccessError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [panError, setPanError] = useState('');
  const [dataError,setDataError] = useState('');
  const [mobileOtpError, setMobileOtpError] = useState('');
  const [aadhaarOtpError, setAadhaarOtpError] = useState('');
  const [pinError, setpinError] = useState('');
  const [aadharError, setAadharError] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [resetLoading,setResetLoading] = useState(false);
  const [verifyLoading,setVerifyLoading] = useState(false);

  const { vertical, horizontal, opensnakbar } = state;





  const setCookie = (name, value, expiryInMinutes) => {
    const expiresAt = new Date().getTime() + expiryInMinutes * 60 * 1000; 
    const item = {
      value: value     
    };
  
  
    localStorage.setItem(name, JSON.stringify(item));
  };
  
  const addDataToCookie = (newData) => {
    let oldData = getCookie('_partnerdata') || {};
  
    console.log(oldData, "OLDDATA");
  
    if (typeof oldData === 'string') {
      oldData = JSON.parse(oldData);
    }
    let currentData = { ...oldData, ...newData };
  
    console.log(currentData, "currentData");
  
    setCookie('_partnerdata', currentData, 3 * 24 * 60); 
  };
  
  const deleteCookie = (name) => {
    localStorage.removeItem(name); 
  };
  
  function getCookie(name) {
    const item = localStorage.getItem(name);
    console.log(item,"item")
    if (item) {
      const parsedItem = JSON.parse(item);
      const currentTime = new Date().getTime();
  
      if (currentTime > parsedItem.expiry) {
        localStorage.removeItem(name); 
        return null;
      }
  
      return parsedItem.value;
    }
  
    return null; 
  }
  


  console.log("State.timer", state.timer)


  React.useEffect(() => {
    const level = getCookie("distribution_network_form");
    const fields = ['mob', 'email', 'pan', 'entity_name', 'pincode', 'pinVerify', "aadhar", 'allpendData'];
    console.log(level, "LEVEL");
  
    if (level) {
      let parsedLevel = level;
      if (typeof level === "string") {
        try {
          parsedLevel = JSON.parse(level);
        } catch (error) {
          console.error("Error parsing level cookie:", error);
        }
      }
  
      const completedField = Object.keys(parsedLevel)[0]; 
      const progressIndex = fields.indexOf(completedField);
  
      console.log('completedField:', completedField); 
      console.log('progressIndex:', progressIndex);   
  
      if (progressIndex !== -1) {
        const updatedData = { ...dataField };
        for (let i = 0; i <= progressIndex; i++) {
          updatedData[fields[i]] = true;
        }
        setData(updatedData);
  
        const userData = getCookie("_partnerdata");
        if (userData) {
          let parsedData = userData;
  
          if (typeof userData === "string") {
            try {
              parsedData = JSON.parse(userData); 
            } catch (error) {
              console.error("Error parsing userData cookie:", error);
            }
          }
  
          console.log(parsedData, "parsedData");

          if(parsedData?.Pan){
            const panArray = parsedData.Pan.split('');
        
        if (panArray.length === 10) {
          let updatedPan = {};
          panArray.forEach((digit, index) => {
            updatedPan[`pan${index + 1}`] = digit;
          });
          
          setPanno(updatedPan);
        }
          }

          if (parsedData?.Aadhar) {
            const aadharArray = parsedData.Aadhar.split('');
            if (aadharArray.length === 12) {
              let updatedAadhar = {};
              aadharArray.forEach((digit, index) => {
                updatedAadhar[`aadhar${index + 1}`] = digit;
              });
              setAdharno(updatedAadhar);
            }
          }
  
          setFormData((prevData) => ({
            ...prevData,
            ...parsedData
          }));
        }
      }
    }
  }, []);
  
  
  console.log(state.enableTimer,"enableTimer")
  React.useEffect(() => {
    let countdown;
    if (state.timer <= 45 ) {
// if(state.enableTimer){

  // console.log(state.enableTimer,"enableTimer");
  countdown = setInterval(() => {
    setState((prevState) => {
      if (prevState.timer <= 1) {
        // console.log(state.timer,"timer");
        clearInterval(countdown);
        // setState({...state,resend:true})
        // handleClose();
        return { ...prevState, timer: 0, enableTimer:false };
      }
      return { ...prevState, timer: prevState.timer - 1 };
    });
  }, 1000);
// }
    }else{
      // console.log(state.enableTimer,"enableTimer");
    }
    return () => clearInterval(countdown);
  }, [state.enableTimer]);
  console.log("state.enableTimer", state.enableTimer)
  const handlesnakbarClick = (newState) => {
    setState({ ...newState, opensnakbar: true });
  };

  const handlesnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState({ ...state, opensnakbar: false });
  };


  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      remainingSeconds < 10 ? "0" : ""
    }${remainingSeconds}`;
  };

  const handleChangeOtp = (e, data) => {
    const { value, name } = e.target;
    // console.log("first otpchnage ==== ", value, "name ---", name)
    // Set OTP state with updated values
    // setOtp({ ...otp, [name]: value });
    setOtp({ ...otp, [name]: value });
    combinedOtp = `${otp.otp1}${otp.otp2}${otp.otp3}${otp.otp4}`;
    setFormData({ ...formData, otp: combinedOtp, otpAadhar: AadharOtpMix })
    console.log("handle chnage ----- ", data, e)
    // Focus on the next input automatically if filled
    if (value && e?.target?.parentElement?.nextSibling) {
      e?.target?.parentElement?.nextSibling?.children[0]?.focus();
    } else {

    }
  };

  const handleOpen = (data) => {
    console.log(" data  ", data, formData)
    setLoading(true);
    if (data == "mob") {
      if (formData?.Mobile_number.length >= 10) {
        dedupApiCall("mobileno", data)

        console.log("mobbb --------- ")

      } else {
        console.log("cghllllllllll")
        setLoading(false);
        // setMobileError('Mobile number must be 10 digits');

      }
    } else {
      console.log("date --- else ", data)
      
      if (formData?.Aadhaar.length >= 12) {
        setAadharError('OTP sent to registered mobile number')

        generateAadhaarOtp();
      setState({...state,enableTimer:true,timer:45})

      } else {
        setLoading(false);
        setAadharError('Aadhar number is not valid!')
      }
    }
  };
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };



  const handleClose = (val) => {
    // setOpen(false);
    console.log("HANDLE CLOSE");
    setState({...state,enableTimer:false,timer:0})
    setFormData({ ...formData, otp: combinedOtp })
    console.log("closew ----- ", val, formData?.otpAadhar.length == 6)

    if (val === "mob") {
      if (formData?.otp.length == 4) {
        // setOpen(false)
        console.log("mibiel done ==== ", formData?.otp.length)
        // dedupApiCall(val);
        console.log("mobbb --------- ", mobileError)
        VarifyMobileOtp()

      }
      // console.log(" model close ", val)

    } else if (val === "aadhaar") {
      console.log("if adhaaarrr done ==== ", formData?.otpAadhar.length)
      if (formData?.otpAadhar.length == 6) {
        // generateAadhaarOtp()
        downloadAadhaarXMLFile()
        // setData({ ...dataField, allpendData: true })
      }
      console.log(" model close ", val)

      setOpen(true)
    }

  }

  
  const PanInputChange = (event) => {
    if (!event?.target) return;  
    const { name, value } = event.target; 
  
    const pan = value?.toUpperCase();
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  
    setPanno((prevPanNo) => ({ ...prevPanNo, [name]: pan }));
  
    setFormData({
      ...formData,
      Pan: Object.values(panNo).join(''),
    });
    console.log(formData?.Pan,"FORM DATA");
  
    if (panRegex?.test(formData?.Pan)) {
      setPanError('PAN number is valid!');
    } else {
      setPanError('PAN number is not valid!');
    }
  
    if (value && event?.target?.parentElement?.nextSibling) {
      event?.target?.parentElement?.nextSibling?.children[0]?.focus();
    }
  };
  
  
  const handlePanKeyDown = (event, index) => {
    const { key } = event;
  
    if (key === 'ArrowRight' && index < 9) {
      event.target.parentElement.nextSibling?.children[0]?.focus();
    }
  
    if (key === 'ArrowLeft' && index > 0) {
      event.target.parentElement.previousSibling?.children[0]?.focus();
    }
  
    if (key === 'Backspace' && !event.target.value) {
      if (index > 0) {
        setPanno((prevPanNo) => {
          const updatedPanNo = { ...prevPanNo };
          updatedPanNo[`pan${index}`] = ''; 
          return updatedPanNo;
        });
  
        event.target.parentElement.previousSibling?.children[0]?.focus();
      }
    }
  };
  
  const handlePanPaste = (event, index) => {
    const pastedValue = event.clipboardData.getData('text').toUpperCase();
    
    if (pastedValue.length === 10 && /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(pastedValue)) {
      const newPan = pastedValue.split('');
      
      newPan.forEach((char, i) => {
        setPanno((prevPanNo) => ({ ...prevPanNo, [`pan${i + 1}`]: char }));
      });
      
      setFormData({ ...formData, Pan: newPan.join('') });
  
      setTimeout(() => {
        document.querySelector(`[name='pan10']`)?.focus(); 
      }, 100); 
    }
  };

  const AdhharInputChange = (event) => {
    const { name, value } = event?.target;
    // console.log("name === ", name, "value ---- ",value)


    setAdharno({ ...adhharNo, [name]: value })
    combinedAadhar = `${adhharNo.aadhar1}${adhharNo.aadhar2}${adhharNo.aadhar3}${adhharNo.aadhar4}${adhharNo.aadhar5}${adhharNo.aadhar6}${adhharNo.aadhar7}${adhharNo.aadhar8}${adhharNo.aadhar9}${adhharNo.aadhar10}${adhharNo.aadhar11}${adhharNo.aadhar12}`
    setFormData({
      ...formData, Aadhaar: combinedAadhar
    })

    if (combinedAadhar.length > 0 && combinedAadhar.length <= 12) {
      console.log(formData?.Aadhaar.length, "adhar number is valid!--- ", combinedAadhar)
      // setAadharError('Aadhhar number is not valid!')

    } else {
      console.log("adhar number is not valid!--- ", combinedAadhar.length)
      setAadharError('')

    }
    if (value && event?.target?.parentElement?.nextSibling) {
      event?.target?.parentElement?.nextSibling?.children[0]?.focus();
    }
    // if (value && event?.target?.parentElement?.nextSibling) {
    //   event?.target?.parentElement?.nextSibling?.children[0]?.focus();
    // } else {

    // } 
    // console.log(" pan chnage --- ", formData?.Pan)
  }

  const handleAadharKeydown = (event, index) => {
    const { keyCode, key, ctrlKey, metaKey } = event;
  
    if ((ctrlKey || metaKey) && key === 'v') {
      return; 
    }
  
    // Check if the key is alphabetic (A-Z, a-z) and block it
    if (
      (keyCode >= 65 && keyCode <= 90) ||  // Uppercase A-Z
      (keyCode >= 97 && keyCode <= 122)    // Lowercase a-z
    ) {
      event.preventDefault(); // Block alphabetic characters
    }
  
    if (
      (keyCode < 48 || keyCode > 57) &&  // Numbers from the main keyboard
      (keyCode < 96 || keyCode > 105) && // Numbers from the numpad
      key !== 'Backspace' &&             // Backspace
      key !== 'Delete' &&                // Delete
      key !== 'ArrowLeft' &&             // Left Arrow
      key !== 'ArrowRight'              // Right Arrow
    ) {
      event.preventDefault(); // Block other non-numeric keys
    }
  
    if (key === 'Backspace' && !event.target.value) {
      if (index > 0) {
        setAdharno((prev) => {
          const updatedAdhar = { ...prev };
          updatedAdhar[`aadhar${index}`] = '';  
          return updatedAdhar;
        });
  
        event.target.parentElement.previousSibling?.children[0]?.focus();
      } else {
        setAdharno((prev) => {
          const updatedAdhar = { ...prev };
          updatedAdhar[`aadhar${index}`] = '';  
          return updatedAdhar;
        });
      }
    }
  
    // Handle focus navigation for ArrowRight and ArrowLeft keys
    if (key === 'ArrowRight' && index < 11) {
      event.target.parentElement.nextSibling?.children[0]?.focus();
    }
  
    if (key === 'ArrowLeft' && index > 0) {
      event.target.parentElement.previousSibling?.children[0]?.focus();
    }
  };
  
  

  const handleAadharPaste = (event, index) => {
    const pastedText = event.clipboardData.getData('Text'); 
    console.log("PastedText", pastedText);
  
    const pasteLength = pastedText.length;
  
    const cleanedText = pastedText.replace(/\D/g, '');
  
    const textToFill = cleanedText.slice(0, 12);
  
    const updatedAdhar = { ...adhharNo };
  
    for (let i = 0; i < textToFill.length; i++) {
      updatedAdhar[`aadhar${index + i + 1}`] = textToFill[i];
    }
  
    setAdharno(updatedAdhar);
  
    const combinedAadhar = Object.values(updatedAdhar).join('');
    setFormData({ ...formData, Aadhaar: combinedAadhar });
  
    if (textToFill.length < 12) {
      document.querySelector(`[name="aadhar${textToFill.length + 1}"]`)?.focus();
    }
  
    event.preventDefault();
  };
  
  
  const handleChange = (event) => {
    const { name, value } = event?.target;

    // Mobile number validation
    // if (name === "Mobile_number" || name === "accessCode" || name === "createPassword") {
    if (name === "Mobile_number" || name === "accessCode" || name === "createPassword") {


      if (/^[0-9]*$/.test(value)) { // Allow only numeric input
        if (value?.length <= 10) {  // Prevent entering more than 10 digits
          setFormData({ ...formData, Mobile_number: value });

          console.log("TESTINGGGGG");
          
          if (value?.length === 10 ||  name === "accessCode" || name === "createPassword") {  // Valid mobile number
            setMobileError(''); // Clear the error if the length is exactly 10
          } else { 
            setMobileError('Mobile number must be 10 digits'); // Show error if it's not 10 digits
          }
        }
      } else {

      if(name !== "createPassword"){
        console.log("TESTING 222");
        setMobileError('Mobile number must be 10 digits'); // Error for invalid characters
      }
      }
      
      

      if (name === 'accessCode') {
        console.log(value, "accessCode ---- ", value?.length, value?.length <= 4)
        setFormData({ ...formData, accessCode: value });
        if (/^[0-9]{4}$/?.test(value) && value?.length <= 4) {
          console.log("number must be 4 digits")
          setAccessError('');
        } else {
          setAccessError('AccessCode number must be 4 digits only');
        }
      }

      if (name === 'createPassword') {

        if (value.length > 15) {
          return;  

        }
        setFormData({ ...formData, createPassword: value });
        // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8}$/;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;

        console.log(value, "createPassword ---- ", value?.length, passwordRegex?.test(value))
        if (passwordRegex?.test(value)) {

          // alert('Password is valid!');
          setCreatepassError('');
        } else {

          setCreatepassError(
            'Password must be between 8 and 15 characters. It must include at least one lowercase letter, one uppercase letter, one number, and one special character.'
          );


        }
      }
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Validate the email field and set error if invalid
      if (name === 'Email') {
        setFormData({ ...formData, [name]: value });

        if (value?.length && !emailPattern?.test(value)) {
          setEmailError('Please enter a valid email address');
        } else {
          setEmailError('');
        }
      }
      if (name === 'Pincode') {

        setData({ ...dataField,pinVerify:false ,aadhar:false, allpendData:false});
        console.log("pincode === ", value?.length, value)
        if (/^[0-9]*$/?.test(value) && value?.length <= 6) {
          setFormData({ ...formData, [name]: value });
          // setpinError('Pincode must be 6 digits');
          // console.log(value.length,"VALUES LENGTH");
        } 
        // else {
        //   setpinError('');
        // }
        if(value.length<6){
          setpinError('Pincode must be 6 digits');
        }else{
          setpinError('');
        }
        
        
      }

    }
  };

  const handleSubmit = async (event) => {
    function validatePassword(password) {
      // Regular expression for password validation
      const pattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,15}$/;
  
      // Test if the password matches the pattern
      return pattern.test(password);
  }
    event.preventDefault();
    const checkPassword = await validatePassword(formData?.createPassword);
    console.log("checkPassword", checkPassword)
    if(!checkPassword){
      setCreatepassError(
        'Password must be between 8 and 15 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character.'
      );
    }
    // Handle form submission, e.g., send data to a server

    if (formData?.Aadhaar && formData?.Profession && formData?.accessCode && formData?.accessCode.length == "4" && formData?.createPassword && formData?.createPassword?.length >= 8 && formData?.createPassword?.length <= 15) {

      console.log("form submitted ---- ", formData)
      await finalOnboardPartner();
      

      // }else if(formData?.accessCode && formData?.accessCode.length === "4"){
      //   console.log("access code form data ---- ", formData)
      // }else if(formData?.createPassword && formData?.createPassword.length === "8"){
      //   console.log("create password form data ---- ", formData) 
    } else {

      console.log('Form not submitted:', formData.accessCode.length == "4", formData?.createPassword.length, formData?.createPassword?.length >= 8 && formData?.createPassword?.length <= 15);
      if (!formData?.accessCode && !formData?.accessCode.length == "4") {
        setAccessError('AccessCode number must be 4 digits only');
        console.log("access code form data ---- ", formData)
      } else if (!formData?.createPassword && formData?.createPassword.length < 8 || formData?.createPassword.length > 15) {
        setCreatepassError(
          'Password must be between 8 and 15 characters. It must include at least one lowercase letter, one uppercase letter, one number, and one special character.'
        );

        console.log("create password form data ---- ", formData)
      }
    }
  };

  const dedupApiCall = async (value, OtpVal) => {

    console.log("dedupApiCall -----------", OtpVal, "value ---- ", value)

    let raw;
    if (value === "mobileno") {
      raw = JSON.stringify({
        // mobileno: "8955803029" // dedup for mobile
        mobileno: formData?.Mobile_number // dedup for mobile
        // email: "test@gmail.com",
        // panno: "CNHJM8298K" // dedup for panno
      });
    }
    if (value === "email") {

      raw = JSON.stringify({
        // mobileno: "8955803029", // dedup for mobile
        email: formData?.Email
        // panno: "CNHJM8298K" // dedup for panno
      });
    }
    if (value === "pan") {
      raw = JSON.stringify({
        // mobileno: "8955803029", // dedup for mobile
        // email: "test@gmail.com",
        panno: formData?.Pan // dedup for panno
      });
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Basic ${PRODUCTION_API_KEY}`);

    setPanError('')
    console.log("final ---- ", raw)
    // extractPAN()
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${PRODUCTION_API_DOMAIN}/gates/1.0/sails/checkPartnerDedup`, requestOptions)
      .then((response) => response.json())
      .then((result) => {

        if (result?.message === "Partner onboarding pending" && result?.data) {
          setIsPartnerOnboardingPending(true); 
          setNiyoLeadID(result?.data?.NiyoLeadID)
          setData((prevState) => ({
            ...prevState,
            email: true, 
            entity_name: true,
            pan: true,
          }));
          
          setFormData((prevFormData) => ({
            ...prevFormData,
            Email: result.data.emailId || prevFormData.Email,
            Entity_Name: result.data.entityName || prevFormData.Entity_Name,
            Pan: result.data.panNo || prevFormData.Pan,
          }));
          
          if (result.data.panNo) {
            const panChars = result.data.panNo.split('');
            const panInputState = {};
            panChars.forEach((char, index) => {
              panInputState[`pan${index + 1}`] = char;
            });
            setPanno(panInputState);
          }

          const mobileNumber = formData?.Mobile_number
          
          deleteCookie("distribution_network_form");
          setCookie("distribution_network_form", {
            pan: true,
            email: true,
            entity_name: result.data.entityName ? true : false,
            mob: mobileNumber ? true : false
          }, 3 * 24 * 60);
          addDataToCookie({
            Email: result.data.emailId,
            Entity_Name: result.data.entityName,
            Pan: result.data.panNo,
            Mobile_number: mobileNumber
          });
          
          setEmailError('');
          setPanError('');
          setMobileError('');
          setLoading(false);
          return;
        }

        if (result && result?.data !== "Partner already Exists") {
          console.log("true ---- ", result.data)
          // handlesnakbarClick({ vertical: 'bottom', horizontal: 'center' })

          if (OtpVal == "mob") {
            // setData({...dataField,aadhar:true})
            setValueOfOtp("mob")
            setMobileError('')
            SentMobileSMSApi(formData?.Mobile_number)


          } else {
            if (OtpVal == "aadhaar") {
              setValueOfOtp("aadhaar")
              setLoading(false)

            }
            if (value === "email") {

              setEmailError('');
              setLoading(false)
              deleteCookie("distribution_network_form");
              setCookie("distribution_network_form",{pan:true},3 * 24 * 60);
              addDataToCookie({Email:formData.Email})
              setData({ ...dataField, pan: true })

            } else if (value === "pan") {
            

              setPanError('')
              extractPAN()
              // setData({ ...dataField, entity_name: true })

            }
          }

        } else {
          console.log("false -- -- ", result.data, value)
          if (OtpVal == "mob") {
            setMobileError(result.data);
          } else {
            if (value === "email") {

              setEmailError(result.data);


            } else if (value === "pan") {
              setPanError(result.data);
              // extractPAN()

              console.log("pan error ----- ", result.data, value === "pan", panError)
            }
          }

        }
      })
      .catch((error) => {
        console.error(error)
      });
  }

  const SentMobileSMSApi = async (dedupData) => {
    console.log("SentMobileSMSApi api calling....", dedupData)
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Basic ${PRODUCTION_API_KEY}`);
    let raw;

    if (dedupData) {
      raw = JSON.stringify({
        "mobile": dedupData
      });

    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${PRODUCTION_API_DOMAIN}/gates/1.0/sweeps/generateOtp`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result)
        if (result.statusCode == 200) {
          setLoading(false)
          setTimeout(() => {            
            setOpen(true)
            // setOpen(false)
            // setData({ ...dataField, email: true })

          }, 1500);

        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error)
      });
  }

  const VarifyMobileOtp = async () => {
    console.log("VarifyMobileOtp calling....")

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Basic ${PRODUCTION_API_KEY}`);

    const raw = JSON.stringify({
      "mobileNo": formData?.Mobile_number,
      "otp": formData?.otp
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${PRODUCTION_API_DOMAIN}/gates/1.0/sweeps/verifyOtp`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        // if (result.data.otpVerificationStatus === "V") {
        if (result.data.otpVerificationStatus) {
          setMobileOtpError(result.message)
          setLoading(false);
          setVerifyLoading(false);
          // handlesnakbarClick({ vertical: 'bottom', horizontal: 'center' })
          setCookie("distribution_network_form",{email:true},3*24*60)
          setCookie("_partnerdata",{Mobile_number:formData.Mobile_number},3*24*60)
          setTimeout(() => {
            setOpen(false)
            setData({ ...dataField, email: true })

          }, 1500);

        } else {
          setLoading(false);
          setVerifyLoading(false);
          setMobileOtpError(result.data.otpVerificationStatus)
        }
      })
      .catch((error) => { console.error(error) });
  }

  const extractPAN = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Basic ${PRODUCTION_API_KEY}`);

    const raw = JSON.stringify({
      // "number": "CZJPM8249K"
      "number": formData?.Pan
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    console.log("extract api calling....", raw)
    fetch(`${PRODUCTION_API_DOMAIN}/gates/1.0/sweeps/extractPAN`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("extract result ---- ", result)
        if (result?.statusCode == "200") {
          setFormData({ ...formData, Entity_Name: result?.data })
          deleteCookie("distribution_network_form");
          setCookie("distribution_network_form",{entity_name:true},3 * 24 * 60);
          addDataToCookie({Pan:formData.Pan,Entity_Name:result?.data},3 * 24 * 60);
          setData({ ...dataField, entity_name: true })
          setLoading(false);
        } else {
          setPanError(result?.message);
          setLoading(false);
          // setPanError('')
        }
      })
      .catch((error) => {
        console.error("extract errr ---- ", error)
        setPanError(error?.message)
      });
  }
  console.log(getCookie("_partnerdata"),"COOKIE");

  const validatePincode = (pincode) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Basic ${PRODUCTION_API_KEY}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    console.log("pin ==== ", pincode)

    fetch(`${PRODUCTION_API_DOMAIN}/gates/1.0/sweeps/validatePincode/${pincode}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("result pincode === ", result.statusCode)

        if(result.statusCode ==  200){
        setLoading(false);
        setFormData({ ...formData, city: result?.data?.pincodeData[0]?.pccityname, state: result?.data?.pincodeData[0]?.cpprovincename })
        addDataToCookie({Pincode:pincode,city:result?.data?.pincodeData[0]?.pccityname,state: result?.data?.pincodeData[0]?.cpprovincename });
        deleteCookie("distribution_network_form");
        setCookie("distribution_network_form",{aadhar:true},3 * 24 * 60);
        setData({ ...dataField, pinVerify: true, aadhar: true })
        }
        else{
          // setData({ ...dataField, email: true })

          console.log(formData.Pincode.length,"formData.Pincode.length");
          setLoading(false);

          setData({...dataField,pinVerify:false})
          
          result.message ? setpinError(result?.message):setpinError("Invalid Pin Number!!!")
          return ;
        }
        
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
        setpinError(error)
      });
    //   fetch("https://pisoauat.niyogin.in/gates/1.0/sweeps/validatePincode/395006", requestOptions)
    // .then((response) => response.text())
    // .then((result) => console.log(result))
    // .catch((error) => console.error(error));
  }

  const partnerOnboardAPI = () => {
    console.log("partnerOnboardAPI api calling....")
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Basic ${PRODUCTION_API_KEY}`);

    const raw = JSON.stringify({
      "mobileno": formData?.Mobile_number,
      "email": formData?.Email,
      "panno": formData?.Pan,
      "name": formData?.Entity_Name
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${PRODUCTION_API_DOMAIN}/gates/1.0/sails/partnerOnboarding`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("partnerOnboarding result ---- ", result)
        if (result?.data?.NiyoLeadID) {
          setLoading(false)
          setData({ ...dataField, pincode: true })
          setNiyoLeadID(result?.data?.NiyoLeadID)
        }else{
          setLoading(false);
          setDataError(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("partnerOnboarding errr ---- ", error)
      });
  }

  const generateAadhaarOtp = (event) => {
    console.log("inside generateAadhaarOtp")
    setState({...state, enableTimer: true })
    console.log("generate Aadharr Otp .....")
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Basic ${PRODUCTION_API_KEY}`);

    const raw = JSON.stringify({
      "aadhaarNo": formData?.Aadhaar,
      "consent": true
    });
    console.log("adhhar api raw --- ", raw)
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${PRODUCTION_API_DOMAIN}/gates/1.0/sweeps/generateAadharOtp`, requestOptions)
      .then((response) => response.json())
      .then((result) => {

        // if(state.enableTimer){
        // setState({...state,timer:10})
        // }

        if (!state.isOtpGenerated) {
          console.log("IF isOtpGenerated ")
          setState({ ...state, timer: 45 });
        }else{
          console.log("ELSE isOtpGenerated")
        }
        console.log("generateAadharOtp result ---- ", result)
        if (result?.data?.requestId) {
          setAadharError('');
          setVerifyLoading(false);
          setLoading(false);
         setState({...state,enableTimer:true,timer:45})
          setResetLoading(false);
          setFormData({ ...formData, aadharReqId: result?.data?.requestId })
          setTimeout(() => {
            setValueOfOtp("aadhaar")
            setOpen(true)
          }, 500);
        }

      })
      .catch((error) => {setLoading(false);setResetLoading(false); setVerifyLoading(false);console.error("generateAadharOtp errr ---- ", error) });
  }

  const downloadAadhaarXMLFile = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Basic ${PRODUCTION_API_KEY}`);

    const raw = JSON.stringify({
      "aadhaarNo": formData?.Aadhaar,
      "requestId": formData?.aadharReqId,
      "aadhaarUpdateHistory": "Y",
      "consent": "Y",
      "otp": formData?.otpAadhar
    });
    console.log("downloadAadhaarXMLFile api raw --- ", raw)
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${PRODUCTION_API_DOMAIN}/gates/1.0/sweeps/downloadAadhaarXMLFile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("downloadAadhaarXMLFile result ---- ", result)
        if (result?.data?.result?.dataFromAadhaar?.PADAF[0]?.docName) {
          setAadhaarOtpError("OTP Verified");
          setVerifyLoading(false);
          setLoading(false);
          setDataFromAadhaar(result?.data.result.dataFromAadhaar)

          // const link = document.getElementsByClassName("download_xml")[0];
          // const url = result?.data?.result?.dataFromAadhaar?.PADAF[0]?.docUrl
          // console.log("first link ---- ", url, result?.data?.result?.dataFromAadhaar?.PADAF[0])
          // link.href = url;
          // link.setAttribute("download", result?.data?.result?.dataFromAadhaar?.PADAF[0]?.docName);
          // document.body.appendChild(link);
          // link.click();

          setOpen(false)
          setData({ ...dataField, allpendData: true })
          const maskedAadhaar = result?.data?.result?.dataFromAadhaar?.maskedAadhaarNumber;
          console.log("maskedAadhaar",maskedAadhaar);
          const aadhaarDigits = maskedAadhaar.replace(/\s+/g, '').split('');

          const updatedAadhar = {};
          aadhaarDigits.forEach((digit, index) => {
            updatedAadhar[`aadhar${index + 1}`] = digit; 
          });
  
          addDataToCookie({aadhaar:adhharNo});
          deleteCookie("distribution_network_form");
          setCookie("distribution_network_form",{allpendData:true},3 * 24 * 60);
          setAdharno(updatedAadhar);
          setOpen(false);
          setIsOtpVerified(true);
        } else {
          setVerifyLoading(false);
          setLoading(false);
          setAadhaarOtpError("OTP Is Not Verified! please Try Again")

        }
      })
      .catch((error) => {
        console.error("downloadAadhaarXMLFile errr ---- ", error)
      });
  }

  const finalOnboardPartner = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Basic ${PRODUCTION_API_KEY}`);
  
    const raw = await JSON.stringify({
      "NiyoLeadID": NiyoLeadID,
      "aadhaar": dataFromAadhaar,
      "profession": formData?.Profession,
      "accesscode": formData?.accessCode,
      "password": formData?.createPassword
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
  
    fetch(`${PRODUCTION_API_DOMAIN}/gates/1.0/sails/onboardPartner`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("API response:", result);
        
        if (result?.statusCode == "200") {
          console.log("Success response:", result);
          deleteCookie("distribution_network_form");
          deleteCookie("_partnerdata");
  
          const currentURL = window.location.href;
      
          if (currentURL.includes("niyogin.com/login")) {
            window.location.href = "https://niyoblu.com/auth/login";
          } else if (currentURL.includes("niyogin.com")) {
            window.location.href = "https://niyoblu.com/auth/login";
          } else if (currentURL.includes("niyoblu.com")) {
            window.location.href = "https://www.niyogin.com/login";
          } else {
            window.location.href = "https://niyoblu.com/auth/login";
          }
        } else {
          const errorMessage = result?.data || result?.message || "Something went wrong!";
          setFormsubmitError(errorMessage);
          console.error("Error in API response:", errorMessage);
        }
      })
      .catch((error) => {
        console.error("Exception occurred:", error);
        setFormsubmitError(error?.message || "Connection error occurred");
      });
  }



  const handleOtpKeyDown = (event, currentIndex, totalFields) => {
    const { key, keyCode, ctrlKey, metaKey } = event;
  
    // Allow paste operation via 'Ctrl + V' or 'Cmd + V'
    if ((ctrlKey || metaKey) && key === 'v') {
      return; // Allow paste, don't prevent default behavior
    }
  
    // Handle backspace: If the current input is empty and we are not in the first field, move to the previous input
    if (key === "Backspace" && event.target.value === "" && currentIndex > 1) {
      const previousInput = document.getElementsByName(`otp${currentIndex - 1}`)[0];
      previousInput.focus();
      setTimeout(() => {
        previousInput.setSelectionRange(previousInput.value.length, previousInput.value.length);
      }, 0);
      return;
    }
  
    // Handle right arrow: Move focus to the next input field
    if (key === "ArrowRight" && currentIndex < totalFields) {
      document.getElementsByName(`otp${currentIndex + 1}`)[0].focus();
      return;
    }
  
    // Handle left arrow: Move focus to the previous input field
    if (key === "ArrowLeft" && currentIndex > 1) {
      const previousInput = document.getElementsByName(`otp${currentIndex - 1}`)[0];
      previousInput.focus();
      setTimeout(() => {
        previousInput.setSelectionRange(previousInput.value.length, previousInput.value.length);
      }, 0);
      return;
    }
  
    // Block all non-numeric characters except Backspace, Delete, and Arrow keys
    if (
      (keyCode < 48 || keyCode > 57) &&  // Numbers from the main keyboard
      (keyCode < 96 || keyCode > 105) && // Numbers from the numpad
      key !== "Backspace" &&             // Backspace
      key !== "Delete" &&                // Delete
      key !== "ArrowLeft" &&             // Left Arrow
      key !== "ArrowRight"               // Right Arrow
    ) {
      event.preventDefault(); // Block any other key
    }
  };
  
  // Handle paste event
const handleOtpPaste = (event, currentIndex) => {
  const pastedText = event.clipboardData.getData('Text').replace(/\D/g, ''); // Ensure only numbers are pasted
  console.log("pastedText", pastedText);

  const pasteLength = pastedText.length;

  // Prevent the default paste behavior
  event.preventDefault();

  // Fill OTP inputs with the pasted text
  for (let i = 0; i < pasteLength; i++) {
    const fieldIndex = currentIndex + i;  // Determine the target field index
    if (fieldIndex <= 6) {  // Assuming the maximum OTP length is 6, adjust as needed
      document.getElementsByName(`otp${fieldIndex}`)[0].value = pastedText[i];
    }
  }

  // Move the focus to the next field after pasting
  const nextFieldIndex = currentIndex + pasteLength;
  const nextField = document.getElementsByName(`otp${nextFieldIndex}`)[0] || document.getElementsByName(`otp6`)[0]; // Focus on next input or the last one
  nextField.focus();

  // Optionally, place the cursor at the end of the next input
  setTimeout(() => {
    nextField.setSelectionRange(nextField.value.length, nextField.value.length); // Ensures the cursor is at the end
  }, 0);
};

  

  return (<>
    <Snackbar open={opensnakbar} autoHideDuration={3000} onClose={handlesnackbarClose}>
      <Alert
        onClose={handlesnackbarClose}
        anchorOrigin={{ vertical, horizontal }}
        severity="success"
        variant="filled"
        sx={{ width: '100%' }}
        key={vertical + horizontal}
      >
        Partner Does Not Exists
      </Alert>
    </Snackbar>
    <FormContainer className='form_first'>
      <form onSubmit={handleSubmit}>
        <Modal
          open={open}
          onClose={() => handleClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>


          <div 
  style={{
    position: 'absolute', // Position relative to the parent container
    top: '-15px',      // Move the button 15px outside the box (you can adjust this)
    right: '-15px',         // Position from the left side
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',  // Circular shape
    backgroundColor: '#FF4D4D', // Red background
    color: 'white',  // White 'X'
    fontSize: '20px',  // Make the 'X' bigger
    cursor: 'pointer', // Add a cursor on hover to indicate it's clickable
    userSelect: 'none', // Prevent text selection
    // marginBottom: '-15px', // Make the bottom part of the circle go outside the box
  }}
  onClick={() => {
    setOpen(false);
    console.log("HANDLE CLOSE")
  }}
>
  X
</div>



            <Typography variant="subtitle1" sx={{ marginBottom: '1rem', textAlign: "center", fontSize: "18px", fontWeight: "600" }} className='text_form_tit'>
              {valueOfOtp === "mob" ? "Mobile (OTP verified)" : "Aadhaar (OTP verified)"}
            </Typography>

            <Box sx={{ textAlign: 'center', padding: '1rem 0rem', width: "100%", marginBottom: "0rem" }} className="otp_divs">
            <OtpBox className='box_otp_form'>
              {[...Array(valueOfOtp === 'mob' ? 4 : 6)].map((_, index) => (
                <OTPInput
                  key={index}
                  className={`otp${index + 1}`}
                  placeholder="-"
                  inputProps={{ maxLength: 1 }}
                  name={`otp${index + 1}`}
                  onKeyDown={(event) => handleOtpKeyDown(event, index + 1, valueOfOtp === 'mob' ? 4 : 6)}
                  onChange={(e) => handleChangeOtp(e, valueOfOtp)}
                  onBlur={(e) => handleChangeOtp(e, valueOfOtp)}
                  // onPaste={(e) => handleOtpPaste(e, index + 1)}
                />
              ))}
            </OtpBox>

              {valueOfOtp === "mob" ?
                <>
                  <p className='error_msg' style={{ marginBottom: "0", color: mobileOtpError ? "green" : "red", opacity: (combinedOtp.length < 1 || combinedOtp.length > 3) && !mobileOtpError ? `0` : `1` }}>
                    {mobileOtpError ? mobileOtpError : "Please enter 4 digit OTP"}
                  </p>
                </> :
                <>
                <div style={{ 
  display: "flex", 
  flexDirection: "row", 
  justifyContent: "center", // Center horizontally
  alignItems: "center", // Center vertically
}}>
                <p>{formatTime(state.timer)}</p>
                <LoadingButton
                loading={resetLoading}
                className='verify_otp' 
                style={{ borderRadius: "15px" }}
                disabled={state.timer === 0 ?false:true}
                onClick={(e)=>{ setResetLoading(true);generateAadhaarOtp(e)}}
                
                >Resend OTP
              </LoadingButton>

              </div>
                  <p className='error_msg' style={{
                    marginBottom: "0", color: aadhaarOtpError == "OTP Verified" ? "green" : "red",
                    opacity: !aadhaarOtpError ? AadharOtpMix.length < 1 || AadharOtpMix.length > 5 ? `0` : `1` : `1`
                  }}>
                    {aadhaarOtpError ? aadhaarOtpError : `Please enter 6 digit OTP`}
                  </p>
                  {/* {aadhaarOtpError} */}
                </>
              }

            </Box>


         
           <>
           {console.log(loading,"LOADING")}
            <LoadingButton loading={verifyLoading} variant="contained" fullWidth="true" className='verify_otp' style={{ width: "50%", borderRadius: "15px" }}
             onClick={()=>{
              setVerifyLoading(true);
              if(state.timer === 0 && valueOfOtp === "aadhaar"){
              generateAadhaarOtp()
              }else{
                handleClose(valueOfOtp === "mob" ? "mob" : "aadhaar")
              }
              
              }}>
              Verify OTP
              </LoadingButton>
           </>
           
          </Box>
        </Modal>

        <p className='text_form_tit_label'>Mobile Number</p>
        <FormField className=''>
          {/* ==={dataField?.email?"true":"false"} == */}
          <FormInput className='input_style_cl' style={{ flexDirection: "column" }} >
            <StyledTextField
              id="Mobile_number"
              name="Mobile_number"
              label="Mobile Number:"
              variant="outlined"
              fullWidth={true}
              value={formData?.Mobile_number}
              maxLength={10}
              disabled={dataField?.email}
              // type='number'
              // onKeyDown={(event) => {
              //   // Allow only numbers, backspace, and delete keys
              //   if (
              //     (event.keyCode < 48 || event.keyCode > 57) &&  // Numbers from the main keyboard
              //     (event.keyCode < 96 || event.keyCode > 105) && // Numbers from the numpad
              //     event.keyCode !== 8 &&                         // Backspace
              //     event.keyCode !== 46                           // Delete
              //   ) {
              //     event.preventDefault(); // Block any other key
              //   }
              // }}
              onInput={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, '');
                  if (e.target.value !== numericValue) {
                    e.target.value = numericValue;
                  }
                }}
                inputProps={{
                  inputMode: 'numeric', 
                  pattern: '[0-9]*', 
                }}
              onChange={handleChange}
              onBlur={handleChange}
              //  error={formData?.Mobile_number?true:false}
              error={(formData?.Mobile_number && formData?.Mobile_number.length !== 10) || mobileError ? true : false} // Conditionally shows error style
              helperText={(formData?.Mobile_number && formData?.Mobile_number.length !== 10) || mobileError ? mobileError : ""}
              FormHelperTextProps={{
                style: { color: (formData?.Mobile_number && formData?.Mobile_number.length !== 10) || mobileError ? 'red' : "" }, // Styling helper text
              }}
            />
            {
              dataField?.email ?
                <>
                </>
                :
                <>
                  <LoadingButton  loading={loading} variant="contained" className='verify_otp' onClick={() => handleOpen("mob")}>Verify OTP</LoadingButton>
                </>
            }
          </FormInput>
        </FormField>

        {dataField?.email ?
          <>
            <p className='text_form_tit_label'>Email</p>
            <FormField className='' style={{ flexDirection: "column" }}>

              <FormInput className='input_style_cl'>
                <StyledTextField
                  id="Email"
                  name="Email"
                  label="Email:"
                  variant="outlined"
                  fullWidth={true}
                  value={formData?.Email}
                  disabled={dataField?.pan}
                  // error={!!mobileError}
                  onChange={handleChange}
                  error={!!emailError} // Shows error style if emailError has content
                  helperText={emailError} // Displays the email error message
                  FormHelperTextProps={{
                    style: { color: emailError ? 'red' : '' }, // Styling helper text in red if there’s an error
                  }}
                />
                {/* Helper text for the mobile input */}
                {/* {mobileError && <FormHelperText error>{mobileError}</FormHelperText>} */}
              </FormInput>
              {
                dataField?.pan ?
                  <>
                  </>
                  :

                  <>
                    <LoadingButton loading={loading} variant="contained" fullWidth="true" className='verify_otp' onClick={() => {
                      console.log("email ckcik ---")
                      if (formData?.Email.length) {
                        setLoading(true);
                        dedupApiCall("email")

                      } else {
                        setEmailError("Please enter a valid email address")
                      }
                    }}>Check Email</LoadingButton>
                  </>
              }
            </FormField>
          </>
          :
          ""
        }

        {dataField?.pan ?
          <>
            <Box sx={{ textAlign: 'center', padding: '10px 0 10px 0px', width: "100%" }} className="otp_divs">
              <Typography variant="subtitle1" sx={{ marginBottom: '10px' }} className='text_form_tit'>
                PAN Number
              </Typography>
              <div style={{ display: "flex", width: "100%", gap: "15px", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

              <OtpBox className="box_otp_form" style={{ width: "calc(100% - 20px)" }}>
                {[...Array(10)].map((_, index) => (
                  <OTPInput
                    key={index}
                    placeholder="-"
                    inputProps={{ maxLength: 1 }}
                    name={`pan${index + 1}`} 
                    value={panNo[`pan${index + 1}`] || ''} 
                    // type="password"
                    onChange={PanInputChange}
                    onBlur={PanInputChange}
                    onKeyDown={(event) => handlePanKeyDown(event, index)}  
                    onPaste={(event) => handlePanPaste(event, index)}  
                    disabled={dataField?.entity_name}
                  />
                ))}
              </OtpBox>


                <p className='error_msg' style={{ marginBottom: "0", color: panError == "PAN number is valid!" ? "green" : "red", opacity: !panError && /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/?.test(formData?.Pan) && formData?.Pan.length && formData?.Pan.length == 10 ? `0` : `1`, display: !panError && /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/?.test(formData?.Pan) && formData?.Pan.length == 10 ? `none` : `block` }}>
                  {panError ? panError : "Please enter a valid PAN Number"}
                </p>



                {
                  dataField?.entity_name ?
                    <></>
                    :
                    <>
                      <LoadingButton loading={loading} variant="contained" className='verify_otp' onClick={() => {
                        console.log(formData, "pan ----- ", formData?.Pan.length)

                        setFormData({ ...formData, Pan: combinedPan })
                        if (formData?.Pan.length && formData?.Pan.length == 10) {
                          console.log("formData?.Pan ==== ", formData?.Pan)
                          let panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/

                          if (panRegex?.test(formData?.Pan)) {
                            console.log("on pan submit valid --- ", formData?.Pan)
                            dedupApiCall("pan")
                          } else {
                            console.log("on pan submit not valid --- ", formData?.Pan)

                          }
                          // setData({ ...dataField, entity_name: true })
                        } else {
                          setPanError("")
                        }
                      }}>Verify Pan</LoadingButton>
                    </>
                }
              </div>
            </Box>
          </>
          : ""
        }

        {dataField?.entity_name ?
          <>
            <p className='text_form_tit_label'>Entity Name</p>

            <FormField className='' style={{ flexDirection: "column" }}>

              <FormInput className='input_style_cl'>
                <StyledTextField
                  id="Entity_Name"
                  name="Entity_Name"
                  label="Entity Name:"
                  variant="outlined"
                  fullWidth={true}
                  value={formData?.Entity_Name}
                  onChange={handleChange}
                  disabled={dataField?.entity_name}
                />
              </FormInput>

              {!dataField?.pincode && !isPartnerOnboardingPending && (
                  <LoadingButton loading={loading} variant="contained" className='verify_otp' 
                    onClick={() => {
                      if (!isPartnerOnboardingPending) {
                        setLoading(true);
                        partnerOnboardAPI();
                        setIsPartnerOnboardingPending(true);
                      }
                    }}>
                    Check Data
                  </LoadingButton>
                )}

              {
                 <p className='error_msg' style={{ marginBottom: "0", color:"red", opacity: 1 , display: !dataError ?`none` : `block` }}>
                 {dataError}
               </p>
              }
            </FormField>
          </>
          : ""
        }

        {dataField?.pincode || isPartnerOnboardingPending ? 
            <>
              <p className='text_form_tit_label'>Pincode</p>

              <FormField style={{ flexDirection: "column" }}>

                <FormInput className='input_style_cl'>
                  <StyledTextField
                    id="Pincode"
                    name="Pincode"
                    label="Pincode:"
                    variant="outlined"
                    fullWidth={true}
                    value={formData?.Pincode}
                    onChange={handleChange}
                    error={!!pinError} // Shows error style if emailError has content
                    helperText={pinError} // Displays the email error message
                    FormHelperTextProps={{
                      style: { color: pinError ? 'red' : '' }, // Styling helper text in red if there’s an error
                    }}
                    onKeyDown={(event) => {
                      // Allow only numbers, backspace, and delete keys
                      if (
                        (event.keyCode < 48 || event.keyCode > 57) &&  // Numbers from the main keyboard
                        (event.keyCode < 96 || event.keyCode > 105) && // Numbers from the numpad
                        event.keyCode !== 8 &&                         // Backspace
                        event.keyCode !== 46                           // Delete
                      ) {
                        event.preventDefault(); // Block any other key
                      }
                    }}
                  />
                </FormInput>
                {
                  dataField?.pinVerify || formData?.Pincode.length < 6 ? <></>
                    :
                    <>
                      <LoadingButton loading={loading} variant="contained" className='verify_otp' onClick={() => {
                        setLoading(true);
                        if (/^[0-9]*$/?.test(formData?.Pincode) && formData?.Pincode.length < 6) {
                          console.log("if dsxd")
                          setLoading(false);
                          
                        } else {
                          console.log("first otpchnage ==== ", formData?.Pincode, "name ---", formData?.Pincode.length)
                          setpinError("")
                          validatePincode(formData?.Pincode)
                          // setData({ ...dataField, pinVerify: true }) 

                        }
                      }}>Verify Pincode</LoadingButton>
                    </>
                }

              </FormField>
              {dataField?.pinVerify ?
                <>
                  <p className='text_form_tit_label'>City</p>

                  <FormField>

                    <FormInput className='input_style_cl'>
                      <StyledTextField
                        id="city"
                        name="city"
                        label="City:"
                        variant="outlined"
                        fullWidth={true}
                        value={formData?.city}
                        disabled={true}
                        onChange={handleChange}

                      />
                    </FormInput>
                  </FormField>
                  <p className='text_form_tit_label'>State</p>

                  <FormField>

                    <FormInput className='input_style_cl'>
                      <StyledTextField
                        id="state"
                        name="state"
                        label="State:"
                        variant="outlined"
                        fullWidth={true}
                        disabled={true}
                        value={formData?.state}
                        onChange={handleChange}
                      />
                    </FormInput>
                  </FormField>
                </> :
                <></>
              }

            </>
            : ""
        }


        {dataField?.aadhar ?
          <>
            <Box sx={{ textAlign: 'center', padding: '1rem 0rem', width: "100%" }} className="otp_divs">
              <Typography variant="subtitle1" sx={{ marginBottom: '1rem' }} className='text_form_tit'>
                Aadhaar Number
              </Typography>
              <OtpBox className='box_otp_form' style={{ marginBottom: "1rem" }}>
                {Array.from({ length: 12 }, (_, index) => (
                  <OTPInput
                    key={`aadhar${index + 1}`} 
                    placeholder="-"
                    inputProps={{ maxLength: 1 }}
                    name={`aadhar${index + 1}`} 
                    value={adhharNo[`aadhar${index + 1}`] || ''}
                    onChange={(e) => AdhharInputChange(e)}
                    onBlur={(e) => AdhharInputChange(e)}
                    onKeyDown={(event) => handleAadharKeydown(event, index)}  
                    onPaste={(event) => handleAadharPaste(event, index)} 
                    disabled={isOtpVerified} 

                  />
                ))}
              </OtpBox>

              <p style={{ marginBottom: "0", color: aadharError == "Aadhar number validated" || aadharError == "OTP sent to registered mobile number" ? "green" : "red", opacity: (combinedAadhar.length < 1 || combinedAadhar.length > 11) && !aadharError ? `0` : `1`, display: (combinedAadhar.length < 1 || combinedAadhar.length > 11) && !aadharError ? `none` : `block` }}>
                {aadharError ? aadharError : "Please enter a valid Aadhar Number"}
              </p>


              {
                dataField?.allpendData ? <></>
                  :
                  <>
                    <LoadingButton loading={loading}variant="contained" className='verify_otp' onClick={() => { handleOpen("aadhaar") }}>Verify OTP</LoadingButton>
                  </>
              }

            </Box>
          </>
          : ""
        }

        {dataField?.allpendData ?
          <>
            <p className='text_form_tit_label'>Profession</p>

            <FormControl fullWidth className='Select_data_form'>
              <InputLabel id="demo-simple-select-label">Profession</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData?.Profession}
                onChange={(e) => {
                  console.log("selct options ---- ", e.target.value)
                  setFormData({ ...formData, Profession: e.target.value })
                }}
              >
                <MenuItem value="CA" className='select_data'>CA</MenuItem>
                <MenuItem value="FC" className='select_data'>FC</MenuItem>
                <MenuItem value="CFC" className='select_data'>CFC</MenuItem>
                <MenuItem value="Insurance Advisor" className='select_data'>Insurance Advisor</MenuItem>
                <MenuItem value="GST or Tax Consultant" className='select_data'>GST or Tax Consultant</MenuItem>
                <MenuItem value="DSA" className='select_data'>DSA</MenuItem>
              </Select>
            </FormControl>



            <p className='text_form_tit_label'>4-Digit Access Code</p>
            <FormField>

              <FormInput className='input_style_cl'>
                <StyledTextField
                  id="accessCode"
                  name="accessCode"
                  label="Create Access Code:"
                  variant="outlined"
                  fullWidth={true}
                  value={formData?.accessCode}
                  onChange={handleChange}
                  // onBlur={handleChange}
                  maxLength={4}
                  // disabled={dataField?.email}
                  // type='number'
                  onKeyDown={(event) => {
                    // Allow only numbers, backspace, and delete keys
                    if (
                      (event.keyCode < 48 || event.keyCode > 57) &&  // Numbers from the main keyboard
                      (event.keyCode < 96 || event.keyCode > 105) && // Numbers from the numpad
                      event.keyCode !== 8 &&                         // Backspace
                      event.keyCode !== 46                           // Delete
                    ) {
                      event.preventDefault(); // Block any other key
                    }
                  }}
                  error={(formData?.accessCode && formData?.accessCode.length !== 4) || accessError ? true : false} // Conditionally shows error style
                  helperText={(formData?.accessCode && formData?.accessCode.length !== 4) || accessError ? accessError : ""}
                  FormHelperTextProps={{
                    style: { color: (formData?.accessCode && formData?.accessCode.length !== 4) || accessError ? 'red' : "" }, // Styling helper text
                  }}
                />
              </FormInput>
            </FormField>

            <p className='text_form_tit_label'>Create Password</p>

            <FormField>

              <FormInput className='input_style_cl'>
                <StyledTextField
                  id="createPassword"
                  name="createPassword"
                  label="Create Password:"
                  variant="outlined"
                  fullWidth={true}

                  maxLength={15}
                  value={formData?.createPassword}
                  onChange={handleChange}
                  error={(formData?.createPassword && (formData?.createPassword.length < 8 || formData?.createPassword.length > 15)) || createpassError ? true : false}  // Error condition for password length
                  helperText={(formData?.createPassword && (formData?.createPassword.length < 8 || formData?.createPassword.length > 15)) || createpassError ? createpassError : ""}  // Helper text showing error message
                  FormHelperTextProps={{
                    style: { color: (formData?.createPassword && (formData?.createPassword.length < 8 || formData?.createPassword.length > 15)) || createpassError ? 'red' : "" },  // Error color
                  }}
                  // error={(formData?.createPassword && formData?.createPassword.length !== 8) || createpassError ? true : false} // Conditionally shows error style
                  // helperText={(formData?.createPassword && formData?.createPassword.length !== 8) || createpassError ? createpassError : ""}
                  // FormHelperTextProps={{
                  //   style: { color: (formData?.createPassword && formData?.createPassword.length !== 8) || createpassError ? 'red' : "" }, // Styling helper text
                  // }}

                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={
                            showPassword ? 'hide the password' : 'display the password'
                          }
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          onMouseUp={handleMouseUpPassword}
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormInput>
            </FormField>

            <p className='error_msg' style={{ marginBottom: "0", color: "red", opacity: !FormsubmitError ? `0` : `1` }}>
              {FormsubmitError}
            </p>
            <p>Please write to at <a href='mailto:info@niyogin.in'>info@niyogin.in</a> if you face any issue.</p>
            <div className='btn_container'>
              <FormButton className='verify_btn get_Started' type="submit" variant="contained" >
                 Get Started
              </FormButton>
            </div>
          </>
          : ""
        }

      </form>
    </FormContainer>
  </>

  );
}

export default FormMuiTab2;