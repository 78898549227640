import './App.css';
import Home from "../src/pages/home.jsx"
import Header from './components/header.jsx';
import Footer from './components/footer.jsx';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Nopage from './pages/Nopage.jsx';
import "./assets/css/main.css"
import './assets/css/style.css'
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Aboutus from './pages/Aboutus.jsx';
import NIYOGINfeed from './components/NIYOGINfeed.jsx';
import EmbeddedFinance from './pages/EmbeddedFinance.jsx';

import Career from './pages/Career.jsx';
import DistributionNetwork from './pages/DistributionNetwork.jsx';

import NewsRoom from './pages/NewsRoom.jsx';
import TermsConditions from './pages/TermsConditions.jsx';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage.jsx';
import ScrollToTop from './components/ScrollToTop.jsx';
import ContactUs from './pages/ContactUs.jsx';

import Blogs from './pages/Blogs.jsx';

import InvestorRelation from './pages/InvestorRelation.jsx';
import BlogDetail from './pages/BlogDetail.jsx';
import { HelmetProvider } from 'react-helmet-async';
import RedirectHandler from './components/RedirectHandler';

const Redirect301 = ({ to }) => {
  useEffect(() => {
    const meta = document.createElement('meta');
    meta.httpEquiv = 'refresh';
    meta.content = `0;url=${to}`;
    document.head.appendChild(meta);
    document.title = `Redirecting to ${to}`;
    return () => {
      document.head.removeChild(meta);
    };
  }, [to]);
  
  return null;
};

const ExternalRedirect301 = ({ url }) => {
  useEffect(() => {
    const meta = document.createElement('meta');
    meta.httpEquiv = 'refresh';
    meta.content = `0;url=${url}`;
    document.head.appendChild(meta);
    
    document.title = `Redirecting to ${url}`;
    
    return () => {
      document.head.removeChild(meta);
    };
  }, [url]);

  return null;
};

const Layout = () => {
  const location = useLocation();

  // Determine the current path and set classes
  const headerClass = location.pathname === '/' ? 'header-home' : 'header-other';
  const webPath = location.pathname;

  return (
    <>
          <RedirectHandler />

      <Header className={headerClass} pathweb={webPath} />
      <ScrollToTop/>
      <Routes>
       
        {/* Main Routes */}
        <Route index element={<Home />} />
        <Route path="about-us" element={<Aboutus />} />
        <Route path="terms-conditions" element={<TermsConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="Embedded-Finance" element={<EmbeddedFinance />} />
        <Route path="careers" element={<Career />} />
        <Route path="distribution-network" element={<DistributionNetwork />} />
        <Route path="Investor-Relations" element={<InvestorRelation />} />
        <Route path="news-room" element={<NewsRoom />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="blogs/:slug" element={<BlogDetail />} />

        {/* 404 Route */}
        <Route path="*" element={<Nopage />} />
      </Routes>
      <NIYOGINfeed/>
      <Footer/>
    </>
  );
};

function App() {
  useEffect(() => {
    // AOS.refreshHard();
    AOS.init();
  }, []);

  return (
    <HelmetProvider>
    <div className="App">
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </div>
    </HelmetProvider>
  );
}

export default App;