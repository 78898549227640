import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectHandler = () => {
  const navigate = useNavigate();
  const path = window.location.pathname + window.location.search;

  useEffect(() => {
    const internalRedirects = {
      '/home': '/',
      '/company': '/about-us',
      '/enquiry': '/contact-us',
      '/apply-now': '/contact-us',
      '/support1': '/contact-us',
      '/en/careers/-/categories/61249': '/careers',
      '/web/guest/terms-conditions': '/terms-conditions',
      '/customer-onboarding': '/distribution-network#collabra_opp',
      '/en/company': '/about-us',
      '/customer-onboarding1': '/distribution-network#collabra_opp',
      '/embedded-lending': '/Embedded-Finance',
      '/blogs/blogposts/Investor-Relations': '/Investor-Relations',
      '/en/unsecured-business-loans': '/Distribution-network',
      '/platform-offerings': '/Embedded-Finance',
      '/investors': '/Investor-Relations',
      '/newsroom': '/news-room',
      '/web/guest/become-a-partner': '/distribution-network#collabra_opp',
      '/faq': '/Investor-Relations',
      
      '/en/company?p_p_id=EmailSubscribePortlet_INSTANCE_niyogin01&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_cacheability=cacheLevelPage': '/about-us',
      '/enquiry?p_p_id=EmailSubscribePortlet_INSTANCE_niyogin01&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_cacheability=cacheLevelPage': '/contact-us',
      '/newsroom?p_p_id=EmailSubscribePortlet_INSTANCE_niyogin01&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_cacheability=cacheLevelPage': '/news-room',
      '/customer-onboarding1?p_p_id=EmailSubscribePortlet_INSTANCE_niyogin01&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_cacheability=cacheLevelPage': '/about-us',




      "/https://blog.niyogin.com"  : "/blogs",
      '/blogs/theethics-of-data-sharing-in-fintech': '/blogs/the-ethics-of-data-sharing-in-fintech',
      '/blogs/the-rise-of-women-s-equality-through-financial-inclusion': '/blogs/the-rise-of-womens-equality-through-financial-inclusion',
      '/the-impact-of-digital-lending-on-personal-finance/Investor-Relations': '/blogs/the-impact-of-digital-lending-on-personal-finance',
      '/the-future-of-fintech-how-gen-ai-is-reshaping-the-industry/Investor-Relations': '/blogs/the-future-of-fintech-how-gen-ai-is-reshaping-the-industry/',
      '/the-future-of-digital-lending-emerging-technologies-and-innovations-in-india/Investor-Relations': '/blogs/the-future-of-digital-lending-emerging-technologies-and-innovations-in-india/',
      '/blogs/blogposts/fraud-detection-and-prevention-in-fintech': '/blogs/fraud-detection-and-prevention-in-fintech',
      '/blogs/blogposts/credit-scoring-and-risk-assessment-in-digital-lending': '/blogs/credit-scoring-and-risk-assessment-in-digital-lending',
    };   
    const externalRedirects = {
      '/rural-tech': 'https://iserveu.in/',
      '/urban-tech': 'https://moneyfront.in/',
      '/investment': 'https://moneyfront.in/',
      '/iserveu': 'https://iserveu.in/',
      '/moneyfrontfs': 'https://moneyfront.in/',
      "/gates/1.0/sweeps/verifyEmailOtp":"https://niyoblu.com/auth/login"
    };

    const investorRedirects = {
      '/investors/announcements/newspaper-publication-stock-exchange-intimation-prior-board-meeting-of-11-02-2019.pdf': true,
      '/investors/corporate-governance/others/composition-of-the-board-and-its-committees.pdf': true,
      '/nouser.jsp': true,
      '/combo/?browserId=other&minifierType&languageId=en_US&b=7010&t=1619538973843&': true,
      '/investors/investor-updates/investor-update-q1-2018-2019.pdf': true,
      '/combo/?browserId=other&minifierType&languageId=en_US&b=7010&t=1643651551689&': true,
      '/investors/reports-and-fillings/shareholding-pattern/shareholding-pattern-31-03-2015.pdf': true,
      '/investors/financial-information/financial-results/annual-results-2018-19.pdf': true,
      '/investors/corporate-governance/agm-egm/nfl-agm-notice-28-09-2017.pdf': true,
      '/investors/financial-information/financial-results/quarterly-results-june-30-2018.pdf': true,
      '/https://blog.niyogin.com': true,
      '/investors/financial-information/financial-results/annual-results-2020-21.pdf': true,
      '/gates/1.0/sweeps/sendIVR': true,
      '/combo/?browserId=other&minifierType&languageId=en_US&b=7010&t=1711947211448&': true,
      '/investors/reports-and-fillings/shareholding-pattern/shareholding-pattern-30-09-2018.pdf': true,
      '/investors/announcements/scheme/compliance-certificate-of-amalgamation.pdf': true,
      '/investors/corporate-governance/others/additional-information.pdf': true,
      '/investors/financial-information/financial-results/subsidiary/annual-results-2020-21-iserveu.pdf': true,
      '/investors/announcements/scheme/auditors-certificate-of-amalgamation.pdf': true,
      '/investors/corporate-governance/agm-egm/postal-ballot-voting-results-28-03-2019.pdf': true,
      '/investors/announcements/prior-intimation-bse-31-08-2020.pdf': true,
      '/investors/announcements/potential-acquisition-of-moneyfront-by-niyogin-fintech-limited.pdf': true,
      '/investors/reports-and-fillings/shareholding-pattern/shareholding-pattern-30-06-2016.pdf': true,
      '/investors/announcements/newspaper-publication-of-prior-bm-intimation-10-11-2020.pdf': true,
      '/investors/corporate-governance/agm-egm/scrutiniser-report-egm-apr-2017.pdf': true,
      '/investors/announcements/intimation-of-board-meeting-18-july-2017.pdf': true,
      '/investors/corporate-governance/agm-egm/postal-ballot-notice-and-form-13-11-2019.pdf': true,
      '/investors/announcements/prior-bm-intimation-to-bse-26-07-2021.pdf': true,
      '/investors/announcements/outcome-of-board-meeting-12-05-2020.pdf': true,
      '/investors/announcements/free-press-journal-newspaper-publication-financial-results-of-q3.pdf': true,
      '/investors/reports-and-fillings/reports-on-corporate-governance/corporate-governance-report-30-06-2019.pdf': true,
      '/investors/announcements/outcome-of-board-meeting-23-07-2020.pdf': true,
      '/investors/reports-and-fillings/shareholding-pattern/shareholding-pattern-31-12-2014.pdf': true,
      '/investors/investor-updates/investor-presentations/niyogin-investor-presentation-may-2019.pdf': true,
      '/investors/reports-and-fillings/shareholding-pattern/shareholding-pattern-31-12-2017.pdf': true,
      '/investors/announcements/related-party-disclosure-30-09-2020.pdf': true,
      '/investors/announcements/appointment-of-secretarial-auditor.pdf': true,
      '/investors/financial-information/financial-results/half-yearly-results-sept-2016.pdf': true,
      '/investors/investor-updates/chairmans-note-q2-2020-2021.pdf': true,
      '/investors/reports-and-fillings/disclosure-on-liquidity-risk/disclosure-on-liquidity-risk-june-2020.pdf': true,
      '/investors/reports-and-fillings/reports-on-corporate-governance/corporate-governance-report-31-03-2021.pdf': true,
      '/investors/investor-updates/ceos-note-q2-2021-2022.pdf': true,
      '/investors/announcements/intimation-for-book-closure-19-05-2021.pdf': true,
      '/combo/?browserId=other&minifierType&languageId=en_US&b=7010&t=1706540445046&': true,
      '/investors/announcements/newspaper-publication-dispatch-of-agm-notice-apla-mahanagar.pdf': true,
      '/investors/corporate-governance/agm-egm/postal-ballot-notice-dec-2016.pdf': true,
      '/investors/announcements/prior-intimation-to-bse-12-05-2020.pdf': true,
      '/combo/?browserId=other&minifierType&languageId=en_US&b=7010&t=1701370407986&': true,
      '/investors/announcements/appointment-of-statutory-auditor-23-07-2020.pdf': true,
      '/investors/corporate-governance/others/investor-grievance-redressal-officer.pdf': true,
      '/investors/announcements/newspaper-publication-prior-board-meeting-notice-of-09-08-2019-navshakti.pdf': true,
      '/investors/reports-and-fillings/shareholding-pattern/shareholding-pattern-31-03-2020.pdf': true,
      '/investors/reports-and-fillings/reports-on-corporate-governance/corporate-governance-report-31-03-2019.pdf': true,
      '/investors/announcements/outcome-of-board-meeting-22-05-2019.pdf': true,
      '/documents/93867/0/going-vacation.pdf/0f52e334-cf42-c230-f6b2-89af1c73a406?t=1575289909270': true,
      '/enquiry?p_p_id=EmailSubscribePortlet_INSTANCE_niyogin01&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_cacheability=cacheLevelPage': true,
      '/documents/93867/0/travel-with-pets.pdf/5252cd71-90d5-edcb-20bf-20ab04432a51?t=1575290276743': true,
      '/en/company?p_p_id=EmailSubscribePortlet_INSTANCE_niyogin01&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_cacheability=cacheLevelPage': true,
      '/newsroom?p_p_id=EmailSubscribePortlet_INSTANCE_niyogin01&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_cacheability=cacheLevelPage': true,
      '/customer-onboarding1?p_p_id=EmailSubscribePortlet_INSTANCE_niyogin01&p_p_lifecycle=2&p_p_state=normal&p_p_mode=view&p_p_cacheability=cacheLevelPage': true,
      '/the-impact-of-digital-lending-on-personal-finance/Investor-Relations': true,
      '/the-future-of-fintech-how-gen-ai-is-reshaping-the-industry/Investor-Relations': true,
      '/the-future-of-digital-lending-emerging-technologies-and-innovations-in-india/Investor-Relations': true,
      '/documents/93867/0/travel-with-pets.pdf/5252cd71-90d5-edcb-20bf-20ab04432a51?t=1575290276743': true,
      '/web/finvestax-global/home?p_p_id=NiyoginContactUs_INSTANCE_u6JsolABGN4E&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&_NiyoginContactUs_INSTANCE_u6JsolABGN4E_javax.portlet.action=contactUs&p_auth=1GHeFpVQ': true,
      '/web/finvestax-global/home?p_p_id=NiyoginContactUs_INSTANCE_u6JsolABGN4E&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&_NiyoginContactUs_INSTANCE_u6JsolABGN4E_javax.portlet.action=contactUs&p_auth=15vDxGIm': true,
      '/web/shree-sunder-consultancy': true,
      '/web/finvestax-global/home?p_p_id=NiyoginContactUs_INSTANCE_u6JsolABGN4E&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&_NiyoginContactUs_INSTANCE_u6JsolABGN4E_javax.portlet.action=contactUs&p_auth=12ul0sQZ': true,
      '/web/vridhi-finance-solutions': true,
      '/web/finvestax-global/home?p_p_id=NiyoginContactUs_INSTANCE_u6JsolABGN4E&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&_NiyoginContactUs_INSTANCE_u6JsolABGN4E_javax.portlet.action=contactUs&p_auth=0bfjwtS3': true,
      '/web/finvestax-global/home?p_p_id=NiyoginContactUs_INSTANCE_u6JsolABGN4E&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&_NiyoginContactUs_INSTANCE_u6JsolABGN4E_javax.portlet.action=contactUs&p_auth=01nnupuX': true,
      '/web/tirupati-financial-services': true,
      '/blogs/government-e-marketplace-everything-you-need-to-know': true,
      '/web/finvestax-global/home?p_p_id=NiyoginContactUs_INSTANCE_u6JsolABGN4E&p_p_lifecycle=1&p_p_state=normal&p_p_mode=view&_NiyoginContactUs_INSTANCE_u6JsolABGN4E_javax.portlet.action=contactUs&p_auth=KAwhY0k7': true,
      '/web/krishna-creation': true,





      '/the-impact-of-digital-lending-on-personal-finance/Investor-Relations': true,
      '/the-future-of-fintech-how-gen-ai-is-reshaping-the-industry/Investor-Relations': true,
      '/the-future-of-digital-lending-emerging-technologies-and-innovations-in-india/Investor-Relations': true,
    };

    if (internalRedirects[path]) {
      navigate(internalRedirects[path], { replace: true });
      return;
    }

    if (externalRedirects[path]) {
      window.location.href = externalRedirects[path];
      return;
    }

    if (investorRedirects[path]) {
      navigate('/Investor-Relations', { replace: true });
      return;
    }

    const isInvestorPath = Object.keys(investorRedirects).some(key => {
      return path.includes(key.split('?')[0]); 
    });

    if (isInvestorPath) {
      navigate('/Investor-Relations', { replace: true });
      return;
    }

  }, [navigate, path]);

  return null;
};

export default RedirectHandler;